
import KirbyLink from "./kirbylink.vue";
import Kirbyimage from "./kirbyimage.vue";
export default {
  name: "homepagetop",
  props: {
    content: Object,
  },
  data() {
    return {
      isHovering: false,
    };
  },
  components: {
    KirbyLink,
    Kirbyimage,
  },
};
